import React, { useEffect, useRef, useState } from "react";
import Widget from "@widget/Widget";
import Provider from "@components/Provider";
import { MemoryRouter, useNavigate } from "react-router-dom";
import TracklistVisibilityProvider from "@widget/components/TracklistVisibilityProvider";
import WidgetPreviewContext from "./WidgetPreviewContext";

type Props = {
  url: string;
  width: number | string;
  height: number;
  onDisableTracklist?: (disabled: boolean) => void;
};

const WidgetPreview: React.FC<Props> = ({
  url,
  width,
  height,
  onDisableTracklist,
}) => {
  const [previewContext, setPreviewContext] = useState({ width, height, url });
  const navigate = useNavigate();
  const t = useRef<NodeJS.Timeout>();

  useEffect(() => {
    navigate(url);
  }, [url, navigate]);

  /**
   * Wait 1s before updating, because widget container size has to change before
   * we want to re-trigger the widget paint
   */
  useEffect(() => {
    if (t.current) {
      clearTimeout(t.current);
    }
    t.current = setTimeout(
      () => setPreviewContext({ width, height, url }),
      1000
    );

    return () => {
      if (t.current) {
        clearTimeout(t.current);
      }
    };
  }, [height, url, width]);

  return (
    <div style={{ width, height }} className="shadow-xl">
      <WidgetPreviewContext.Provider value={previewContext}>
        <Provider>
          <TracklistVisibilityProvider
            onDisableTracklistOptionChange={onDisableTracklist}
          >
            <Widget />
          </TracklistVisibilityProvider>
        </Provider>
      </WidgetPreviewContext.Provider>
    </div>
  );
};

const PreviewRouter: React.FC<Props> = ({ url, ...props }) => {
  return (
    <MemoryRouter initialEntries={[url]}>
      <WidgetPreview url={url} {...props} />
    </MemoryRouter>
  );
};

export default PreviewRouter;
