import _get from "lodash/get";

const defaultGetBaseUrl = (contextName: string, contextId: string) => {
  return `${contextName}/${contextId.trim()}`;
};

const dzrContexts = [
  {
    name: "album",
    regex: /\/(album)\/([^?]+)/,
  },
  {
    name: "playlist",
    regex: /\/(playlist)\/([^?]+)/,
  },
  {
    name: "artist",
    regex: /\/(artist)\/([^?]+)/,
    getBaseUrl: (contextName: string, contextId: string) => {
      return `${contextName}/${contextId}/top_tracks`;
    },
  },
  {
    name: "show",
    regex: /\/(show)\/([^?]+)/,
  },
  {
    name: "track",
    regex: /\/(track)\/([^?]+)/,
  },
  {
    name: "episode",
    regex: /\/(episode)\/([^?]+)/,
  },
];

const mapDeezerUrl = (url: string) => {
  const widgetBaseUrl = dzrContexts.reduce(
    (acc, { regex, name, getBaseUrl = defaultGetBaseUrl }) => {
      if (!regex.test(url)) return acc;
      const contextId = _get(regex.exec(url), "2");
      return getBaseUrl(name, contextId || "undefined");
    },
    defaultGetBaseUrl("playlist", "undefined")
  );

  return widgetBaseUrl;
};

export default mapDeezerUrl;
