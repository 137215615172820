import mapDeezerUrl from "@widget/contexts/mapDeezerUrl";

type Props = {
  theme: string;
  deezerUrl: string;
  tracklist: boolean;
  radius?: boolean;
  app_id?: string;
  idType?: boolean;
  autoplay?: boolean;
  hideRedirectModal?: boolean;
  hideExtraActions?: boolean;
};

const getWidgetPreviewUrl = ({
  theme,
  deezerUrl,
  tracklist,
  radius,
  app_id,
  idType,
  autoplay,
  hideRedirectModal,
  hideExtraActions,
}: Props) => {
  const widgetContentUrl = mapDeezerUrl(deezerUrl);
  let url = `/widget/${theme}/${widgetContentUrl}`;
  const options = [];

  if (tracklist === false) {
    options.push("tracklist=false");
  }

  if (radius === false) {
    options.push("radius=false");
  }

  if (app_id) {
    options.push(`app_id=${app_id}`);
  }

  if (idType) {
    options.push(`idType=upc`);
  }

  if (autoplay) {
    options.push(`autoplay=1`);
  }

  if (hideRedirectModal) {
    options.push(`hideRedirectModal=true`);
  }

  if (hideExtraActions) {
    options.push(`hideExtraActions=true`);
  }

  if (options.length > 0) {
    const params = options.reduce((acc, option) => {
      if (!acc) {
        return option;
      }
      return `${acc}&${option}`;
    });

    url += `?${params}`;
  }

  return url;
};

export default getWidgetPreviewUrl;
