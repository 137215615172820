type Props = {
  path: string;
  width: string | number;
  height: string | number;
  baseUrl?: string;
};

const getWidgetIframeCode = ({
  baseUrl = "https://widget.deezer.com",
  path,
  width,
  height,
}: Props) => {
  return `<iframe title="deezer-widget" src="${baseUrl}${path}" width="${width}" height="${height}" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>`;
};

export default getWidgetIframeCode;
